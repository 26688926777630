import styled from "styled-components";

/**
 * OldName: PageViewContainer
 */
export const PageViewWrapper = styled.div<{
  hasPinnedSidebar: boolean;
  sidebarWidth: number;
  isPreview?: boolean;
  isPublished: boolean;
  showBreadcrumb?: boolean;
}>`
  ${({ isPublished }) => (isPublished ? "" : "width: inherit;")};
  ${({ hasPinnedSidebar, isPreview, sidebarWidth }) =>
    (!isPreview && `margin-left: ${sidebarWidth}px;`) ||
    (isPreview && `padding-left: ${sidebarWidth}px; margin: 0 auto;`) ||
    "margin: 0 auto;"}
  ${({ showBreadcrumb }) => (showBreadcrumb ? "padding-top: 13px;" : "")}
`;

export const PageView = styled.div<{ width: string }>`
  height: 100%;
  position: relative;
  width: ${(props) => props.width};
`;

export const PageHeader = styled.div<{
  width: string;
  hasSidebarPinned: boolean;
}>`
  height: 100%;
  width: ${(props) => props.width};
  padding-left: 12px;
  > .custom-breadcrumb {
    position: relative;
  }
`;
